.button {
  border-radius: 0.7rem !important;
  padding: 0.4rem 1.7rem !important;
}

.country {
  border-radius: 0.7rem !important;
  padding: 0.4rem 1rem !important;
}

.country:focus {
  outline: none;
}