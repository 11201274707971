@import '@theme/variables.scss';

.input {
  &::placeholder {
    color: $primary-text-color !important;
    opacity: 1;
  }

  outline: none;
  border-radius: 0.8rem;
}
