@import '@theme/variables.scss';

.navBarContainer {
  width: 20rem;
}

.logo {
  height: 10rem;
}

.content {
  justify-content: space-between;
}

.logoutBtn {
  border-radius: 0.8rem !important;
  line-height: 2rem !important;
}
