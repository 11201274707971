@import '@theme/variables.scss';

.topScrollButton {
  width: auto;
  height: auto;
  background: $secondary-text-color !important;
  position: absolute;
  border: none !important;
  border-radius: 4px;
  top: 88.35%;
  left: 86.1111111%;
  padding: 0 !important;
  margin: 0 auto;
}
.topScrollButtonInsideBox {
  background: transparent;
  width: 24px;
  height: 24px;
  margin: 8px;
}
.arrowTopBorder {
  background: $primary-border-color;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 2px;
}
.arrow {
  width: 2px;
  height: 18px;
  display: block;
  background: $primary-border-color;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;

  &::before {
    content: '';
    width: 2px;
    height: 11.879393923934px;
    background: $primary-border-color;
    border-radius: 2px;
    position: absolute;
    transform: rotate(-45deg);
    top: 13px;
    left: 23px;
  }
  &::after {
    content: '';
    width: 2px;
    height: 11.879393923934px;
    background: $primary-border-color;
    border-radius: 2px;
    position: absolute;
    transform: rotate(45deg);
    top: 13px;
    left: 15px;
  }
}
