.listItemImage {
  width: 6rem;
  height: 4rem;
}

.input {
  border-radius: 0.4rem;
  padding: 0.5rem;
  height: 3rem;
}

.input::placeholder {
  padding: 1.5rem;
  background: url('../../assets/icons/search.svg') left / contain no-repeat;
}
