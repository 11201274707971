@import '@theme/variables.scss';

$smallImageSize: 11.2rem;

.image {
  width: 100%;
}

.smallImage {
  width: $smallImageSize;
  height: $smallImageSize;
}

.input {
  opacity: 0;
  left: 0;
  top: 0;
}

.delete {
  width: 1rem;
  height: 1rem;
  top: -0.5rem;
  right: -0.5rem;
  padding-bottom: 0.1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $primary-hover-color !important;
  }
}
