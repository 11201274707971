@import '@theme/variables.scss';

.label {
  line-height: 2rem;
  cursor: pointer;
  font-weight: bold;
}

.input {
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  width: 22px;
  height: 22px;
  top: 0;
  left: 15px;
  background: $background-color;
  border: 2px solid $secondary-text-color !important;
  border-radius: 12px;

  &::after {
    display: none;
    content: '';
    height: 14px;
    width: 14px;
    margin: 2px;
    background-color: $secondary-text-color;
    border-radius: 12px;
  }
}

.checked {
  &::after {
    display: flex;
  }
}
