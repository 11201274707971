.starImg {
  cursor: pointer;
}
.ratersNumberLabelSmall {
  font-size: 0.65rem;
  padding-left: 0.333rem;
}
.ratersNumberLabelBig {
  font-size: 2.6rem;
  padding-left: 1.5rem;
}

.resetStarsSmall {
  width: 0.65rem;
}
.resetStarsBig {
  width: 2.6rem;
}
.resetStarsVisibleOnly {
  cursor: default !important;
}
.starImgVisibleOnly {
  cursor: default !important;
}
.resetStars {
  background: transparent;
  height: 50%;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
