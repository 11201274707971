@import '@theme/variables.scss';

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='black'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-clip: unset !important;
  border-radius: 0.7rem !important;
}

option {
  text-transform: capitalize;
}

option:checked {
  background-image: $secondary-text-color url('../../assets/icons/search.svg') right / contain no-repeat;
}

select[multiple]:focus option:checked {
  background: $secondary-text-color linear-gradient(0deg,  $secondary-text-color 0%,  $secondary-text-color 0%);
}

select option:checked {
  background: $secondary-text-color linear-gradient(0deg,  $secondary-text-color 0%,  $secondary-text-color 0%);
}
