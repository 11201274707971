@import '@theme/variables.scss';

.forgottenPasswordScreenContainer {
  justify-content: center;
  align-items: center;
}

.form {
  min-width: 30rem;
}

.forgottenPasswordContainerSize {
  width: 28rem;
}

.card {
  text-align: left !important;
  border-radius: 1.7rem !important;
  padding: 0.8rem;
}

.button {
  border-radius: 1rem !important;
  font-family: 'TruenoBd';
  font-size: 1.5em !important;
  padding: 0.5rem 4rem !important;
}

.input {
  border-radius: 0.8rem !important;

  &:focus {
    border-color: $info-border-color !important;
    box-shadow: none !important;
  }
}