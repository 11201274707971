@import '@theme/variables.scss';

.action span {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary-hover-color !important;
  }
}
