@import '@theme/variables.scss';

.link {
  color: $primary-text-color;
  text-decoration: underline;

  &:hover {
    color: $primary-hover-color;
  }
}

.small {
  font-size: 0.8em;
}
