@import '@theme/variables.scss';

@font-face {
  font-family: 'TruenoBd';
  src: local('TruenoBd'), url('../../assets/fonts/TruenoBd.otf') format('truetype');
}

@font-face {
  font-family: 'TruenoLt';
  src: local('TruenoLt'), url('../../assets/fonts/TruenoLt.otf') format('truetype');
}

.loginScreenContainer {
  justify-content: center;
  align-items: center;
}

.form {
  min-width: 30rem;
}

.card {
  text-align: left !important;
  border-radius: 1.7rem !important;
  padding: 0.8rem;
}

.input {
  border-radius: 0.8rem !important;

  &:focus {
    border-color: $info-border-color !important;
    box-shadow: none !important;
  }
}

.button {
  border-radius: 1rem !important;
  font-family: 'TruenoBd';
  font-size: 1.5em !important;
  padding: 0.5rem 4rem !important;
}

.loginContainerSize {
  width: 28rem;
}