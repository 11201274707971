@import '@theme/variables.scss';

$size: 1rem;
$border-radius: 0.2rem;
$label-padding: 1rem;

.switchContainer {
  padding-left: 2rem !important;
}

.label {
  left: $label-padding;
  padding-left: 5%;

  &::before {
    cursor: pointer;
    border-color: $primary-border-color !important;
    border-radius: $border-radius !important;
    height: $size + 0.35rem !important;
    width: (2 * $size) + 0.35rem !important;
    left: -2rem - $label-padding !important;
  }

  &::after {
    width: $size !important;
    height: $size !important;
    border-radius: $border-radius !important;
    top: 0.45rem !important;
    left: -1.8rem - $label-padding !important;
    background-color: $primary-hover-color !important;
  }
}

.checked {
  &::before {
    background-color: $background-color !important;
  }

  &::after {
    transform: translateX($size) !important;
    background-color: $secondary-text-color !important;
  }
}
