@import '@theme/variables.scss';

.link {
  a {
    color: $light-text-color;

    &:active,
    &:hover {
      text-decoration: none;
      color: $secondary-text-color;
    }
  }
}
