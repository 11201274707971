.listItemImage {
  width: 6rem;
  height: 7rem;
}

.input {
  border-radius: 0.4rem;
  padding: 0.5rem;
  height: 3rem;
}

.input::placeholder {
  padding: 1.5rem;
  background: url('../../assets/icons/search.svg') left / contain no-repeat;
}

.iconSize {
  width: 1.2rem;
  min-width: 1.2rem;
  max-width: 1.2rem;
}

.button {
  height: 3rem;
  border-radius: 0.4rem !important;
}
