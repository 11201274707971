@import '@theme/variables.scss';

.textArea {
  background-clip: unset !important;
  border-radius: 0.7rem !important;

  &::placeholder {
    color: $primary-text-color !important;
  }
}
