@import '@theme/variables.scss';

.logoutBtn {
  display: inherit;
  height: 30px;
  background: transparent;
  color: $light-text-color;
  letter-spacing: 0.04em;
  cursor: pointer;
}

.logoutBtn:hover {
  color: $secondary-text-color;
}

.logoutIconContainer {
  background: transparent;
  max-width: 13px !important;
  min-width: 13px !important;
  width: 13px !important;
  height: 18px;
  border-color: $secondary-text-color;
  border-width: 2px 2px 2px 0;
  border-radius: 0 2px 2px 0;
  border-style: solid;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    width: 2px;
    border-radius: 2px 0 0 2px;
    background: linear-gradient(
      $secondary-text-color 6px,
      transparent 0 12px,
      $secondary-text-color 0
    );
  }
}
.arrow {
  display: block;
  width: 9px;
  height: 2px;
  background: $secondary-text-color;
  margin-top: 6px;
  margin-left: -4px;

  &::before {
    position: relative;
    left: -5px;
    top: -3px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 5px solid $secondary-text-color;
    border-bottom: 4px solid transparent;
  }
}
